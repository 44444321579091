<template>
  <div class="form-wrapper" :class="formState" style="border-radius: 36px !important; backdrop-filter: blur(10px); padding: 46px 5px;">
    <div class="inner-wrapper">
      
      <div class="page-success" v-if="success">
        <div class="success">{{ success }}</div>
      </div>

      <div class="form">
        <form action="" @focusout="change" @input="change" @submit="submit" novalidate>
          <h2 style="color:#fff; text-align: center;">Reset Password</h2>
          <div class="form-group">
            <div class="input-field-wrapper" :class="{ invalid: validation.email.errorMessage }">
              <div class="input-field">
                <input type="email" name="email" id="email" placeholder="Email Address..." data-rules='["required","email"]' v-model="email" />
              </div>
            </div>
            <p class="error-message" v-if="validation.email.errorMessage">
              {{ validation.email.errorMessage }}
            </p>
          </div>

        <div class="form-error" v-if="error">
          <div class="error">{{ error }}</div>
        </div>

          <button type="submit" class="">Reset</button>
          <div class="bottom-additional-links">
            <router-link to="/login" class="back-to-login" style="color:#fff !important;">Back to Login</router-link>
          </div>
        </form>
      </div>
      <div class="loading-overlay">
        <div class="loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import formValidation from '@/mixins/formValidation';

export default {
  name: 'ResetPasswordForm',
  mixins: [formValidation],
  data() {
    return {
      validation: {
        inputElements: [],
        email: {},
      },
      email: '',
    };
  },
  mounted() {
    this.validation.inputElements = [...this.$el.querySelectorAll('input[data-rules]')];
    this.initValidation();
  },
  methods: {
    send() {
      this.error = null;
      this.$store
        .dispatch('resetPassword', {
          email: this.email,
        })
        .then(() => {
          this.formState = null;
          this.success = 'Please check your inbox for details.';
        })
        .catch((error) => {
          this.formState = null;
          this.error = error;
        });
    },
  },
};
</script>

<style>
.auth-form button {
  box-shadow: 0 2px 30px 0 rgb(150 104 0 / 0%) !important;
  background-image: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%) !important;
}
</style>
