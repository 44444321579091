<template>
  <div class="auth-form-view">
    <div class="container l">
      <div class="auth-form reset-password-form">
        <ResetPasswordForm />
      </div>
    </div>
  </div>
</template>

<script>
import ResetPasswordForm from "@/components/auth/ResetPasswordForm.vue";

export default {
  name: "ResetPasswordView",
  components: {
    ResetPasswordForm,
  },
};
</script>

<style lang="scss">
@import "../scss/partials/variables.scss";
@import "../scss/partials/auth-form-view.scss";
</style>
